<template>
  <div class="background-head" id="background-head">
    <div class="background-head-container">
      <v-container>
        <v-row style="z-index: 50; position: relative">
          <v-col cols="11" md="8" class="title-container" style="margin: auto">
            <div>
              <p>{{ header[["firstText"]] }}</p>
              <p class="secondText">{{ header[["secondText"]] }}</p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderComponent",

  data: () => ({}),

  computed: {
    header: {
      get() {
        return this.$store.getters["headerModule/isHeaderActive"];
      },
      set(newState) {
        if (this.$store.getters["headerModule/isHeaderActive"] !== newState) {
          this.$store.dispatch("headerModule/toogleHeaderState", newState);
        }
      },
    },
  },
};
</script>
