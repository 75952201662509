const state = {
  cde: {
    title: "",
    conditionalParagraph: "",
  },
};
const getters = {
  isCdeActive(state) {
    return state.cde;
  },
};
const mutations = {
  changeCdeState(state, cde) {
    state.cde = cde;
  },
};
const actions = {
  toogleCdeState(context, cde) {
    context.commit("changeCdeState", cde);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
