<template>
  <div id="descriptionContainer">
    <v-container>
      <v-row>
        <v-col cols="8" class="descriptionText">
          {{ description }}
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "descriptionComponent",

  data: () => ({}),

  computed: {
    description: {
      get() {
        return this.$store.getters["descriptionModule/isDescriptionActive"];
      },
      set(newState) {
        if (
          this.$store.getters["descriptionModule/isDescriptionActive"] !==
          newState
        ) {
          this.$store.dispatch(
            "descriptionModule/toogleDescriptionState",
            newState
          );
        }
      },
    },
  },
};
</script>
