<template>
  <div id="we-are">
    <div v-if="loading">
      <LottieAnimation
        path="lottie/loader.json"
        :width="loaderWidth"
        :speed="2"
      />
    </div>
    <div v-else>
      <header-component />
      <description-component />
      <div class="weAreComponent">
        <v-container>
          <v-row>
            <v-col
              cols="11"
              md="5"
              class="order-2 order-md-1 imgCol"
              style="margin: auto"
              id="weArePhoto"
            >
              <v-img
                :src="`https://res.cloudinary.com/interprocsysmex/image/upload/v1675025753/ahjende/somosahj/WhatsApp_Image_2022-11-30_at_7.52.49_AM_q0vjun.jpg`"
                class="weArePhoto"
                id="weAreDisplay"
              />
            </v-col>
            <v-col
              cols="11"
              md="7"
              class="order-1 order-md-2 descriptionColContainer weAreFlexElement"
              style="margin: auto"
            >
              <v-container
                class="weAreComponentContainer"
                id="weAreComponentContainer"
              >
                <v-row>
                  <v-col cols="10" style="margin: auto">
                    <p class="homeDescription d-none d-md-block">
                      Presidente ejecutivo de AHJ ENDE
                    </p>
                    <p class="weAreTitle">DHC. Antonio H. Jasso</p>
                    <p
                      class="homeDescription d-block d-md-none"
                      style="text-align: center"
                    >
                      Presidente ejecutivo de AHJ ENDE
                    </p>
                  </v-col>
                  <v-col cols="10" style="margin: auto">
                    <p class="homeDescription">
                      Nuestro logro m&aacute;s importante c&oacute;mo
                      instituci&oacute;n est&aacute; en cada uno de los
                      j&oacute;venes que egresan de nuestras aulas. Como
                      Instituci&oacute;n educativa tenemos la responsabilidad de
                      contribuir a la sociedad y qu&eacute; mejor que a
                      trav&eacute;s de la formaci&oacute;n de individuos
                      productivos con un enfoque de crecimiento continuo bajo
                      una filosofía de ganar - ganar.
                    </p>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div class="photoWeAreSeparator" />
      <v-container>
        <v-row>
          <v-col cols="8" class="descriptionText" style="font-size: 23pt">
            Filosof&iacute;a empresarial
          </v-col>
        </v-row>
      </v-container>
      <div class="mNVContainer">
        <v-container>
          <v-row>
            <v-col cols="11" sm="6" style="margin: auto">
              <p class="mNVTitle">Misión</p>
              <div class="subtitleDelimiter" />
              <p class="text">
                Somos una empresa mexicana dedicada a desarrollar una cultura
                empresarial dirigida a la sociedad en general, a trav&eacute;s
                de nuestros distintos planes de estudio, con un modelo de
                educaci&oacute;n disruptivo basado en la experiencia con el
                objetivo de generar en cada uno de nuestros futuros lideres un
                enfoque competitivo en el ambiente empresarial y de negocios.
              </p>
            </v-col>
            <v-col cols="11" sm="6" style="margin: auto">
              <p class="mNVTitle">Visión</p>
              <div class="subtitleDelimiter" />
              <p class="text">
                Ser la mejor opci&oacute;n en M&eacute;xico, para la formación
                integral del individuo a nivel medio superior y superior;
                desarrollando habilidades y competencias laborales,
                empresariales y de negocios, que les permitan a nuestros
                egresados mejorar su calidad de vida.
              </p>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div class="benefitsContainer">
        <v-container>
          <v-row>
            <v-col cols="8" class="contactDescriptionText contactTitle">
              ¿Por qu&eacute; estudiar en
              <span style="font-weight: bold"> AHJ ENDE </span>
              ?
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="11" sm="6" md="4" lg="3" style="margin: auto">
              <p class="number">1</p>
              <p>
                Experiencia empresarial y de negocios inmersiva para aprendizaje
                pr&aacute;ctico.
              </p>
            </v-col>
            <v-col cols="11" sm="6" md="4" lg="3" style="margin: auto">
              <p class="number">2</p>
              <p>
                Plataforma y aplicaciones para tu uso 24/7 adapt&aacute;ndose a
                tu estilo de vida.
              </p>
            </v-col>
            <v-col cols="11" sm="6" md="4" lg="3" style="margin: auto">
              <p class="number">3</p>
              <p>
                Modelo acad&eacute;mico pr&aacute;ctico para el desarrollo de
                habilidades &uacute;tiles en la vida de nuestros estudiantes.
              </p>
            </v-col>
            <v-col cols="11" sm="6" md="4" lg="3" style="margin: auto">
              <p class="number">4</p>
              <p>
                Planes de estudio que se adaptan a tus tiempos y necesidades.
              </p>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div class="cdeComponent">
        <div class="cdeDecoration" id="weAreDecoration" />
        <v-container class="cdeContainer" id="weAreContainer">
          <v-row>
            <v-col cols="11" md="6" class="descriptionCol" style="margin: auto">
              <v-container id="weAreElementComponent">
                <v-row>
                  <v-col cols="11" style="margin: auto">
                    <p class="cdeTitle">Nuestra historia</p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="11" style="margin: auto">
                    <p class="cdeDescription" style="text-align: justify">
                      Los principios de nuestra institución se remontan a hace
                      una década, cuando nuestro fundador, el DHC Antonio Jasso
                      decidió brindar a la población en general una nueva opción
                      para continuar estudios y formación especializada en
                      negocios, con un enfoque práctico y al alcance de todas
                      las personas. Siendo una institución educativa
                      especializada en negocios laica e incluyente hemos ayudado
                      a más de 10,000 personas a seguir estudiando para
                      continuar avanzando en sus logros personales y
                      profesionales y tú puedes formar parte de ellos.
                    </p>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col cols="11" md="6" class="imgCol" style="margin: auto">
              <v-img
                :src="`https://res.cloudinary.com/interprocsysmex/image/upload/v1673644196/ahjende/somosahj/somosahj-img3_u9tw6z.jpg`"
              />
            </v-col>
          </v-row>
        </v-container>
      </div>
      <v-container>
        <v-row>
          <v-col cols="8" class="descriptionText">
            «Formando a los profesionistas y empresarios del ma&ntilde;ana»
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import HeaderComponent from "../components/HeaderComponent.vue";
import descriptionComponent from "../components/descriptionComponent.vue";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

var setLocationDecorationHeight;
var setLocationContainerPadding;
var setPhotoHeight;

export default {
  name: "WeAreView",

  title() {
    return `AHJ ENDE Quienes Somos`;
  },

  data: () => ({
    loading: true,
  }),

  components: {
    LottieAnimation,
    HeaderComponent,
    descriptionComponent,
  },

  created() {
    this.header[["firstText"]] = "Somos";
    this.header[["secondText"]] = "AHJ ENDE";
    this.headerPhoto =
      "https://res.cloudinary.com/interprocsysmex/image/upload/v1685164181/ahjende/somosahj/We_Are_apqvmg_xcsdiy.jpg";
    this.description =
      "Somos un grupo multidisciplinario especializado en el área de negocios. Tenemos más de 10 años de experiencia en el sector de la educación privada.";
    this.weAre = true;
  },

  mounted() {
    setTimeout(() => {
      this.loading = false;
      setTimeout(() => {
        this.setHeaderPhoto();
        this.setDescriptionBackground();
        setLocationDecorationHeight = setInterval(
          this.setLocationDecorationHeight,
          100
        );
        setLocationContainerPadding = setInterval(
          this.setLocationContainerPadding,
          100
        );
        setPhotoHeight = setInterval(this.setPhotoHeight, 100);
      }, 100);
    }, 1000);
  },

  beforeDestroy() {
    clearInterval(setLocationDecorationHeight);
    clearInterval(setLocationContainerPadding);
    clearInterval(setPhotoHeight);
  },

  destroyed() {
    this.weAre = false;
  },

  methods: {
    setHeaderPhoto() {
      var header = window.document.getElementById("background-head");
      header.style.backgroundImage = `url(${this.headerPhoto})`;
    },

    setDescriptionBackground() {
      var description = window.document.getElementById("descriptionContainer");
      description.style.background = "white";
      description.style.color = "black";
    },

    setLocationDecorationHeight() {
      var decoration = window.document.getElementById("weAreDecoration");
      var container = window.document.getElementById("weAreContainer");
      decoration.style.height = `${container.clientHeight}px`;
    },

    setLocationContainerPadding() {
      var weAreELement = window.document.getElementById(
        "weAreComponentContainer"
      );
      var weAreContainer = window.document.getElementById(
        "weAreElementComponent"
      );
      var templateContainer = window.document.getElementById("footerContainer");
      var windowWidth = window.innerWidth;
      var paddingElement = (windowWidth - templateContainer.clientWidth) / 2;
      weAreELement.style.marginRight = `${paddingElement}px`;
      weAreELement.style.width = `${
        windowWidth > 959
          ? templateContainer.clientWidth / 2
          : templateContainer.clientWidth
      }px`;
      weAreContainer.style.marginLeft = `${paddingElement}px`;
      weAreContainer.style.width = `${
        windowWidth > 959
          ? templateContainer.clientWidth / 2
          : templateContainer.clientWidth
      }px`;
    },

    setPhotoHeight() {
      var weAreContainer = window.document.getElementById("weAreContainer");
      var weArePhoto = window.document.getElementById("weArePhoto");
      var weAreDisplay = window.document.getElementById("weAreDisplay");
      weArePhoto.style.height = `${weAreContainer.clientHeight + 50}px`;
      weAreDisplay.style.height = `${weAreContainer.clientHeight + 50}px`;
    },
  },

  computed: {
    loaderWidth() {
      var windowWidth = window.innerWidth;
      return windowWidth > 300 ? 300 : windowWidth;
    },

    description: {
      get() {
        return this.$store.getters["descriptionModule/isDescriptionActive"];
      },
      set(newState) {
        if (
          this.$store.getters["descriptionModule/isDescriptionActive"] !==
          newState
        ) {
          this.$store.dispatch(
            "descriptionModule/toogleDescriptionState",
            newState
          );
        }
      },
    },

    header: {
      get() {
        return this.$store.getters["headerModule/isHeaderActive"];
      },
      set(newState) {
        if (this.$store.getters["headerModule/isHeaderActive"] !== newState) {
          this.$store.dispatch("headerModule/toogleHeaderState", newState);
        }
      },
    },

    headerPhoto: {
      get() {
        return this.$store.getters["headerPhotoModule/isHeaderPhotoActive"];
      },
      set(newState) {
        if (
          this.$store.getters["headerPhotoModule/isHeaderPhotoActive"] !==
          newState
        ) {
          this.$store.dispatch(
            "headerPhotoModule/toogleHeaderPhotoState",
            newState
          );
        }
      },
    },

    weAre: {
      get() {
        return this.$store.getters["weAreModule/isWeAreActive"];
      },
      set(newState) {
        if (this.$store.getters["weAreModule/isWeAreActive"] !== newState) {
          this.$store.dispatch("weAreModule/toogleWeAreState", newState);
        }
      },
    },
  },
};
</script>
