<template>
  <div id="index">
    <div v-if="loading">
      <LottieAnimation
        path="lottie/loader.json"
        :width="loaderWidth"
        :speed="1"
      />
    </div>
    <div v-else>
      <div class="index-background-head">
        <div class="index-background-head-container"></div>
      </div>
      <div class="homeDescriptionContainer">
        <v-container>
          <v-row>
            <v-col
              cols="11"
              sm="6"
              md="4"
              lg="3"
              class="descriptionCols"
              style="margin: auto"
            >
              <v-img
                :src="'https://res.cloudinary.com/interprocsysmex/image/upload/v1674501260/ahjende/landpage/sticker4_n3mekg.png'"
                class="descriptionImg"
              />
            </v-col>
            <v-col
              cols="11"
              sm="6"
              md="4"
              lg="3"
              class="descriptionCols"
              style="margin: auto"
            >
              <v-img
                :src="'https://res.cloudinary.com/interprocsysmex/image/upload/v1674501260/ahjende/landpage/sticker3_b4essi.png'"
                class="descriptionImg"
                style="width: 100%; max-width: 65%"
              />
            </v-col>
            <v-col
              cols="11"
              sm="6"
              md="4"
              lg="3"
              class="descriptionCols"
              style="margin: auto"
            >
              <v-img
                :src="'https://res.cloudinary.com/interprocsysmex/image/upload/v1674501260/ahjende/landpage/sticker2_yeqhzr.png'"
                class="descriptionImg"
              />
            </v-col>
            <v-col
              cols="11"
              sm="6"
              md="4"
              lg="3"
              class="descriptionCols"
              style="margin: auto"
            >
              <v-img
                :src="'https://res.cloudinary.com/interprocsysmex/image/upload/v1674501260/ahjende/landpage/sticker1_izqu89.png'"
                class="descriptionImg"
              />
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div class="instalationComponent">
        <div class="descriptionComponent" id="descriptionDecoration" />
        <v-container id="descriptionContainer">
          <v-row>
            <v-col
              cols="11"
              md="6"
              class="order-2 order-md-1 imgCol"
              style="margin: auto"
            >
              <v-img
                :src="`https://res.cloudinary.com/interprocsysmex/image/upload/v1673644353/ahjende/landpage/img-landpage2_fe9m13.jpg`"
              />
            </v-col>
            <v-col
              cols="11"
              md="6"
              class="order-1 order-md-2 descriptionColContainer"
              style="margin: auto"
            >
              <v-container id="descriptionComponentContainer">
                <v-row>
                  <v-col cols="11" style="margin: auto">
                    <p class="homeTitle">Nuestras instalaciones</p>
                  </v-col>
                  <v-col cols="11" style="margin: auto">
                    <p class="homeDescription">
                      Nuestras instalaciones est&aacute;n dise&ntilde;adas para
                      brindar a nuestros alumnos espacios inmersivos para elevar
                      la experiencia empresarial y de negocios.
                    </p>
                    <p class="homeDescription">
                      Espacios y &aacute;reas creadas especialmente para liberar
                      todo el potencial de nuestros alumnos mediante el
                      desarrollo de habilidades prácticas para la vida diaria ya
                      sea como emprendedor o colaborando con alguna empresa.
                    </p>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div class="benefitsContainer">
        <v-container>
          <v-row>
            <v-col cols="8" class="contactDescriptionText contactTitle">
              ¿Por qu&eacute; estudiar en
              <span style="font-weight: bold"> AHJ ENDE </span>
              ?
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="11" sm="6" md="4" lg="3" style="margin: auto">
              <p class="number">1</p>
              <p>
                Experiencia empresarial y de negocios inmersiva para aprendizaje
                pr&aacute;ctico.
              </p>
            </v-col>
            <v-col cols="11" sm="6" md="4" lg="3" style="margin: auto">
              <p class="number">2</p>
              <p>
                Plataforma y aplicaciones para tu uso 24/7 adapt&aacute;ndose a
                tu estilo de vida.
              </p>
            </v-col>
            <v-col cols="11" sm="6" md="4" lg="3" style="margin: auto">
              <p class="number">3</p>
              <p>
                Modelo acad&eacute;mico pr&aacute;ctico para el desarrollo de
                habilidades &uacute;tiles en la vida de nuestros estudiantes.
              </p>
            </v-col>
            <v-col cols="11" sm="6" md="4" lg="3" style="margin: auto">
              <p class="number">4</p>
              <p>
                Planes de estudio que se adaptan a tus tiempos y necesidades.
              </p>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div class="photoIndexSeparator" />
      <v-img
        :src="'https://res.cloudinary.com/interprocsysmex/image/upload/v1674620450/ahjende/landpage/cintilla-mishley_wv3vs8.jpg'"
        class="indexPhoto"
      />
      <div class="coursesDisplay">
        <v-container>
          <v-row>
            <v-col cols="11" sm="6" md="4" style="margin: auto">
              <p class="coursesTitle">Licenciaturas</p>
              <div class="coursesCols">
                <div
                  class="courseDiv"
                  v-on:click="
                    goToRoute(
                      `/carreras?career=${career[['name']]}`,
                      '#careers'
                    )
                  "
                  v-for="(career, idx) in careers"
                  :key="idx"
                >
                  <p class="course">{{ career[["name"]] }}</p>
                </div>
              </div>
            </v-col>
            <v-col cols="11" sm="6" md="4" style="margin: auto">
              <p class="coursesTitle">Bachilleratos</p>
              <div class="coursesCols centerCol">
                <div
                  class="courseDiv"
                  v-on:click="
                    goToRoute(
                      `/preparatoria?highschool=${course[['name']]}`,
                      '#highschool'
                    )
                  "
                  v-for="(course, idx) in highschool"
                  :key="idx"
                >
                  <p class="course">{{ course[["name"]] }}</p>
                </div>
              </div>
            </v-col>
            <v-col cols="11" sm="6" md="4" style="margin: auto">
              <p class="coursesTitle">Diplomados</p>
              <div class="coursesCols">
                <div
                  class="courseDiv"
                  v-on:click="
                    goToRoute(
                      `/diplomados?course=${course[['name']]}`,
                      '#courses'
                    )
                  "
                  v-for="(course, idx) in courses"
                  :key="idx"
                >
                  <p class="course">{{ course[["name"]] }}</p>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <chooseYourCDEComponent />
      <CDESChooserComponent />
      <contact-form />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import contactForm from "../components/ContactForm.vue";
import chooseYourCDEComponent from "../components/chooseYourCDEComponent.vue";
import CDESChooserComponent from "../components/CDESChooserComponent.vue";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

var setCoursesHeight;
var setLocationDecorationHeight;
var setLocationContainerPadding;

export default {
  name: "HomeView",

  title() {
    return `AHJ ENDE Inicio`;
  },

  data: () => ({
    loading: true,
    careers: [],
    courses: [],
    highschool: [],
  }),

  components: {
    LottieAnimation,
    contactForm,
    chooseYourCDEComponent,
    CDESChooserComponent,
  },

  created() {
    this.cde[["title"]] = "Mapa de ubicaciones";
    this.cde[["conditionalParagraph"]] =
      "Ubica tu CDE (Centro de Desarrollo Empresarial)";
    this.index = true;
    this.getCareersData();
  },

  beforeDestroy() {
    clearInterval(setCoursesHeight);
    clearInterval(setLocationDecorationHeight);
    clearInterval(setLocationContainerPadding);
  },

  destroyed() {
    this.index = false;
  },

  methods: {
    getCareersData() {
      axios
        .get("/careers", {
          params: {},
          headers: {},
        })
        .then((response) => {
          var data = response.data;
          this.careers = data[["careers"]];
        })
        .finally(() => {
          this.getCoursesData();
        });
    },

    getCoursesData() {
      axios
        .get("/courses", {
          params: {},
          headers: {},
        })
        .then((response) => {
          var data = response.data;
          this.courses = data[["courses"]];
        })
        .finally(() => {
          this.getHighschoolData();
        });
    },

    getHighschoolData() {
      axios
        .get("/highschool", {
          params: {},
          headers: {},
        })
        .then((response) => {
          var data = response.data;
          this.highschool = data[["highschool"]];
        })
        .finally(() => {
          this.loading = false;
          setTimeout(() => {
            this.setContactBackground();
            setCoursesHeight = setInterval(this.setCoursesHeight, 100);
            setLocationDecorationHeight = setInterval(
              this.setLocationDecorationHeight,
              100
            );
            setLocationContainerPadding = setInterval(
              this.setLocationContainerPadding,
              100
            );
          }, 100);
        });
    },

    setContactBackground() {
      var contact = window.document.getElementById("contactFormContainer");
      contact.style.background = "white";
    },

    setCoursesHeight() {
      var coursesDiv = window.document.getElementsByClassName("courseDiv");
      var courses = window.document.getElementsByClassName("course");
      var maxHeight = 0;
      for (let i = 0; i < courses.length; i++)
        if (courses[i].clientHeight > maxHeight)
          maxHeight = courses[i].clientHeight;
      for (let j = 0; j < courses.length; j++)
        coursesDiv[j].style.height = `${maxHeight}px`;
    },

    goToRoute(a, route) {
      this.$router.push(a);
      setTimeout(() => this.goTo(route, { duration: 300 }), 200);
    },

    goTo(target, options) {
      this.$vuetify.goTo(target, options);
    },

    setLocationDecorationHeight() {
      var decoration = window.document.getElementById("descriptionDecoration");
      var container = window.document.getElementById("descriptionContainer");
      decoration.style.height = `${container.clientHeight}px`;
    },

    setLocationContainerPadding() {
      var cdeContainer = window.document.getElementById(
        "descriptionComponentContainer"
      );
      var templateContainer = window.document.getElementById("footerContainer");
      var windowWidth = window.innerWidth;
      var paddingElement = (windowWidth - templateContainer.clientWidth) / 2;
      cdeContainer.style.marginRight = `${paddingElement}px`;
      cdeContainer.style.width = `${
        windowWidth > 959
          ? templateContainer.clientWidth / 2
          : templateContainer.clientWidth
      }px`;
    },
  },

  computed: {
    loaderWidth() {
      var windowWidth = window.innerWidth;
      return windowWidth > 300 ? 300 : windowWidth;
    },

    cde: {
      get() {
        return this.$store.getters["cdeModule/isCdeActive"];
      },
      set(newState) {
        if (this.$store.getters["cdeModule/isCdeActive"] !== newState) {
          this.$store.dispatch("cdeModule/toogleCdeState", newState);
        }
      },
    },

    index: {
      get() {
        return this.$store.getters["indexModule/isIndexActive"];
      },
      set(newState) {
        if (this.$store.getters["indexModule/isIndexActive"] !== newState) {
          this.$store.dispatch("indexModule/toogleIndexState", newState);
        }
      },
    },
  },
};
</script>
