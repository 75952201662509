<template>
  <div class="cdeComponent">
    <div class="cdeDecoration" id="locationDecoration" />
    <v-container class="cdeContainer" id="locationContainer">
      <v-row>
        <v-col cols="11" md="6" class="descriptionCol" style="margin: auto">
          <v-container id="cdeDescriptionContainer">
            <v-row>
              <v-col cols="11" style="margin: auto">
                <p class="cdeTitle">{{ cde[["title"]] }}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="11" style="margin: auto">
                <p class="cdeDescription">
                  {{ cde[["conditionalParagraph"]] }}
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="11" style="margin: auto">
                <p class="cdeSubtitle">Atención al cliente e inscripciones</p>
                <p class="cdeDescription">
                  55 7651 5535 / 55 5995 1685 / 55 1627 1320
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="11" style="margin: auto">
                <p class="cdeSubtitle">Correo institucional</p>
                <p class="cdeDescription">administracion@ahjende.com</p>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-col cols="11" md="6" class="padding-mapa" style="margin: auto">
          <div class="canvas-mapa">
            <div class="google-maps">
              <iframe
                :src="map"
                frameborder="0"
                style="border: 0"
                allowfullscreen=""
                aria-hidden="false"
                tabindex="0"
              />
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
var setLocationDecorationHeight;
var setLocationContainerPadding;

export default {
  name: "chooseYourCDEComponent",

  mounted() {
    setLocationDecorationHeight = setInterval(
      this.setLocationDecorationHeight,
      100
    );
    setLocationContainerPadding = setInterval(
      this.setLocationContainerPadding,
      100
    );
  },

  beforeDestroy() {
    clearInterval(setLocationDecorationHeight);
    clearInterval(setLocationContainerPadding);
  },

  methods: {
    setLocationDecorationHeight() {
      var decoration = window.document.getElementById("locationDecoration");
      var container = window.document.getElementById("locationContainer");
      decoration.style.height = `${container.clientHeight}px`;
    },

    setLocationContainerPadding() {
      var cdeContainer = window.document.getElementById(
        "cdeDescriptionContainer"
      );
      var templateContainer = window.document.getElementById("footerContainer");
      var windowWidth = window.innerWidth;
      var paddingElement = (windowWidth - templateContainer.clientWidth) / 2;
      cdeContainer.style.marginLeft = `${paddingElement}px`;
      cdeContainer.style.width = `${
        windowWidth > 959
          ? templateContainer.clientWidth / 2
          : templateContainer.clientWidth
      }px`;
    },
  },

  computed: {
    cde: {
      get() {
        return this.$store.getters["cdeModule/isCdeActive"];
      },
      set(newState) {
        if (this.$store.getters["cdeModule/isCdeActive"] !== newState) {
          this.$store.dispatch("cdeModule/toogleCdeState", newState);
        }
      },
    },

    map: {
      get() {
        return this.$store.getters["mapModule/isMapActive"];
      },
      set(newState) {
        if (this.$store.getters["mapModule/isMapActive"] !== newState) {
          this.$store.dispatch("mapModule/toogleMapState", newState);
        }
      },
    },
  },
};
</script>
