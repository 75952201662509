<template>
  <v-app>
    <navbar-component v-if="!privacy" />
    <v-main id="appComponentStyle" :class="{ open: open }">
      <div class="networkContainer">
        <p>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                :href="`https://wa.me/5564625048?text=${'Me gustaría tener informes acerca de lo que AHJ ENDE tiene para mí.'}`"
                target="_blank"
              >
                <v-icon color="white" v-bind="attrs" v-on="on">
                  mdi-whatsapp
                </v-icon>
              </v-btn>
            </template>
            Informes
          </v-tooltip>
          <br />
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon href="mailto:contacto@ahjende.com" target="_blank">
                <v-icon color="white" v-bind="attrs" v-on="on">
                  mdi-email
                </v-icon>
              </v-btn>
            </template>
            Correo
          </v-tooltip>
        </p>
      </div>
      <side class="side" />
      <router-view class="view" />
    </v-main>
    <v-footer padless color="#0092b7" v-if="!privacy">
      <v-container id="footerContainer">
        <v-row class="footerFirstRow">
          <v-col cols="2" class="d-none d-md-inline-flex">
            <v-img
              src="https://lh3.googleusercontent.com/HzodaZ9MDOP58YA3znLNUiJMVjKeVi5wu-_ZBkRZT33WMuj-iqknlmIb7DFTSKNrXp0=w2400"
              class="logoImage"
              id="footerImage"
            />
          </v-col>
          <v-col cols="10" class="d-none d-md-inline-flex" id="colContainer">
            <v-container class="colContainer">
              <v-row>
                <v-col
                  cols="6"
                  lg="3"
                  class="menuCols"
                  style="cursor: pointer"
                  id="menuCols"
                  v-on:click="openWindow()"
                >
                  Aviso de privacidad
                </v-col>
                <v-col
                  cols="6"
                  lg="3"
                  class="menuCols"
                  style="cursor: pointer"
                  id="menuCols"
                  v-on:click="
                    contact
                      ? goTo('#contact', { duration: 300 })
                      : goToRoute('/contacto', '#contact')
                  "
                >
                  Contacto
                </v-col>
                <v-col
                  cols="6"
                  lg="3"
                  class="menuCols"
                  style="cursor: pointer"
                  id="menuCols"
                  v-on:click="openURL('https://plataforma.ahjende.com/')"
                >
                  Plataforma Tecnológica
                </v-col>
                <v-col cols="6" lg="3" class="menuCols" id="menuCols">
                  <v-btn
                    icon
                    href="https://www.facebook.com/escueladenegociosydesarrolloempresarial"
                    target="_blank"
                  >
                    <v-icon color="white"> mdi-facebook </v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    href="https://www.youtube.com/@ahj-endeescueladenegocios4351/featured"
                    target="_blank"
                  >
                    <v-icon color="white"> mdi-youtube </v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    href="https://www.instagram.com/g.ende.ci/"
                    target="_blank"
                  >
                    <v-icon color="white"> mdi-instagram </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
        <v-row class="footerSecondRow">
          <v-col
            cols="6"
            class="colContainer"
            style="margin: auto; font-size: small"
          >
            Derechos Reservados ⓒ {{ new Date().getFullYear() }}, Escuela de
            Negocios y Desarrollo Empresarial, S. C.
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
import axios from "axios";
import navbarComponent from "./components/navbar.vue";
import side from "./components/sideComponent.vue";

export default {
  name: "App",

  data: () => ({}),

  components: {
    navbarComponent,
    side,
  },

  created() {
    this.apiCheck();
  },

  methods: {
    apiCheck() {
      axios
        .get("/", {
          params: {},
          headers: {},
        })
        .then((response) => {
          var data = response.data;
          console.log(data[["message"]]);
        });
    },

    openWindow() {
      this.goToRoute("/aviso-de-privacidad", "#privacy");
    },

    setFooterHeight() {
      var footerLogo = window.document.getElementById("colContainer");
      var menuCols = window.document.getElementById("menuCols");
      menuCols.style.height = `${footerLogo.clientHeight}px`;
    },

    goToRoute(a, route) {
      this.$router.push(a);
      setTimeout(() => this.goTo(route, { duration: 300 }), 200);
    },

    goTo(target, options) {
      this.$vuetify.goTo(target, options);
    },

    openURL(url) {
      window.open(url, "_blank");
    },
  },

  computed: {
    contact: {
      get() {
        return this.$store.getters["contactModule/isContactActive"];
      },
      set(newState) {
        if (this.$store.getters["contactModule/isContactActive"] !== newState) {
          this.$store.dispatch("contactModule/toogleContactState", newState);
        }
      },
    },

    open: {
      get() {
        return this.$store.getters["sideModule/isSideActive"];
      },
      set(newState) {
        if (this.$store.getters["sideModule/isSideActive"] !== newState) {
          this.$store.dispatch("sideModule/toogleSideState", newState);
        }
      },
    },

    privacy: {
      get() {
        return this.$store.getters["privacyModule/isPrivacyActive"];
      },
      set(newState) {
        if (this.$store.getters["privacyModule/isPrivacyActive"] !== newState) {
          this.$store.dispatch("privacyModule/tooglePrivacyState", newState);
        }
      },
    },
  },
};
</script>
<style>
@import "./assets/styles/styles.scss";
</style>
