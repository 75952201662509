<template>
  <div id="scholars">
    <div v-if="loading">
      <LottieAnimation
        path="lottie/loader.json"
        :width="loaderWidth"
        :speed="2"
      />
    </div>
    <div v-else>
      <header-component />
      <description-component />
      <div class="courseCharacteristicsComponent">
        <v-container>
          <v-row>
            <v-col
              cols="11"
              sm="6"
              md="4"
              class="courseCharacteristicsCardContainer"
              style="margin: auto"
              v-for="(scholar, i) in scholarCharacteristics"
              :key="i"
            >
              <v-card width="250px" height="250" class="courseCard">
                <v-container>
                  <v-row>
                    <v-col cols="11" style="margin: auto">
                      <p class="courseName">
                        {{ scholar }}
                      </p>
                    </v-col>
                    <v-col cols="5" class="courseSeparator" />
                    <v-col cols="11" style="margin: auto">
                      <p
                        class="courseDescription"
                        style="cursor: pointer"
                        v-on:click="goToRoute('/becas', '#scholarships')"
                      >
                        Conocer más
                      </p>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <v-container>
        <v-row v-if="position !== 0">
          <v-col cols="11" style="text-align: center; margin: auto">
            <v-btn icon v-on:click="prevScholar()">
              <v-icon color="#0092b7"> mdi-arrow-up </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <courses-wrapper-component />
          </v-col>
        </v-row>
        <v-row v-if="position !== 2">
          <v-col cols="11" style="text-align: center; margin: auto">
            <v-btn icon v-on:click="nextScholar()">
              <v-icon color="#0092b7"> mdi-arrow-down </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <contact-form />
      <div class="cdeComponent">
        <div class="cdeDecoration" id="scholarsDecoration" />
        <v-container class="cdeContainer" id="scholarsContainer">
          <v-row>
            <v-col cols="11" md="6" class="descriptionCol" style="margin: auto">
              <v-container id="scholarsElementComponent">
                <v-row>
                  <v-col cols="11" style="margin: auto">
                    <p class="cdeTitle">Nuestro compromiso</p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="11" style="margin: auto">
                    <p class="cdeDescription" style="text-align: justify">
                      AHJ - ENDE está comprometido a generar en nuestros
                      estudiantes un espíritu emprendedor, en el cual, puedan
                      desarrollar competencias empresariales de carácter
                      propedéutico al mismo tiempo generando una visión
                      empresarial, a través de cursos, talleres y diplomados,
                      mismos que apertura un panorama nuevo para el estudiante,
                      en un marco de valores que marcan la visión y liderazgo
                      del alumno.
                    </p>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col cols="11" md="6" class="imgCol" style="margin: auto">
              <v-img
                :src="`https://res.cloudinary.com/interprocsysmex/image/upload/v1673644266/ahjende/ofertaeducativa/ofedu-img8_dqbsre.jpg`"
              />
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderComponent from "../components/HeaderComponent.vue";
import descriptionComponent from "../components/descriptionComponent.vue";
import contactForm from "../components/ContactForm.vue";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import coursesWrapperComponent from "../components/coursesWrapperComponent.vue";
import axios from "axios";

var setLocationDecorationHeight;
var setLocationContainerPadding;
export default {
  name: "ScholarsView",

  title() {
    return `AHJ ENDE Asuntos Escolares`;
  },

  data: () => ({
    position: 0,
    loading: true,
    scholarCharacteristics: [
      "Aplicar a Licenciatura",
      "Aplicar a Bachillerato",
      "Aplicar a Diplomado",
    ],
  }),

  components: {
    LottieAnimation,
    HeaderComponent,
    descriptionComponent,
    contactForm,
    coursesWrapperComponent,
  },

  created() {
    this.getSearchElements();
    this.header[["firstText"]] = "Conoce nuestra";
    this.header[["secondText"]] = "Oferta educativa";
    this.headerPhoto =
      "https://res.cloudinary.com/interprocsysmex/image/upload/v1673848598/ahjende/ofertaeducativa/Scholar_mvcuz9.jpg";
    this.description =
      "Nuestros programas de estudios están diseñados para brindarte la mejor calidad de conocimiento para tu aprovechamiento y desarrollo, contamos con modalidades presenciales y en línea, consulta con tu asesor inicios de ciclo en cada modalidad.";
    this.scholars = true;
  },

  beforeDestroy() {
    clearInterval(setLocationDecorationHeight);
    clearInterval(setLocationContainerPadding);
  },

  destroyed() {
    this.scholars = false;
  },

  methods: {
    prevScholar() {
      this.position -= 1;
      this.getSearchElements();
    },

    nextScholar() {
      this.position += 1;
      this.getSearchElements();
    },

    getSearchElements() {
      var end = ["/courses", "/highschool", "/careers"];
      var wrapperPos = ["courses", "highschool", "careers"];
      axios
        .get(end[this.position], {
          params: {},
          headers: {},
        })
        .then((response) => {
          var data = response.data;
          this.coursesWrapper = data[[wrapperPos[this.position]]];
        })
        .finally(() => {
          this.loading = false;
          setTimeout(() => {
            this.setHeaderPhoto();
            this.setContactBackground();
            setLocationDecorationHeight = setInterval(
              this.setLocationDecorationHeight,
              100
            );
            setLocationContainerPadding = setInterval(
              this.setLocationContainerPadding,
              100
            );
          }, 100);
        });
    },

    setHeaderPhoto() {
      var header = window.document.getElementById("background-head");
      header.style.backgroundImage = `url(${this.headerPhoto})`;
    },

    setContactBackground() {
      var contact = window.document.getElementById("contactFormContainer");
      contact.style.background = "white";
    },

    setLocationDecorationHeight() {
      var decoration = window.document.getElementById("scholarsDecoration");
      var container = window.document.getElementById("scholarsContainer");
      decoration.style.height = `${container.clientHeight}px`;
    },

    setLocationContainerPadding() {
      var scholarsContainer = window.document.getElementById(
        "scholarsElementComponent"
      );
      var templateContainer = window.document.getElementById("footerContainer");
      var windowWidth = window.innerWidth;
      var paddingElement = (windowWidth - templateContainer.clientWidth) / 2;
      scholarsContainer.style.marginLeft = `${paddingElement}px`;
      scholarsContainer.style.width = `${
        windowWidth > 959
          ? templateContainer.clientWidth / 2
          : templateContainer.clientWidth
      }px`;
    },

    goToRoute(a, route) {
      this.$router.push(a);
      setTimeout(() => this.goTo(route, { duration: 300 }), 200);
    },

    goTo(target, options) {
      this.$vuetify.goTo(target, options);
    },
  },

  computed: {
    loaderWidth() {
      var windowWidth = window.innerWidth;
      return windowWidth > 300 ? 300 : windowWidth;
    },

    coursesWrapper: {
      get() {
        return this.$store.getters[
          "coursesWrapperModule/isCoursesWrapperActive"
        ];
      },
      set(newState) {
        if (
          this.$store.getters["coursesWrapperModule/isCoursesWrapperActive"] !==
          newState
        ) {
          this.$store.dispatch(
            "coursesWrapperModule/toogleCoursesWrapperState",
            newState
          );
        }
      },
    },

    description: {
      get() {
        return this.$store.getters["descriptionModule/isDescriptionActive"];
      },
      set(newState) {
        if (
          this.$store.getters["descriptionModule/isDescriptionActive"] !==
          newState
        ) {
          this.$store.dispatch(
            "descriptionModule/toogleDescriptionState",
            newState
          );
        }
      },
    },

    header: {
      get() {
        return this.$store.getters["headerModule/isHeaderActive"];
      },
      set(newState) {
        if (this.$store.getters["headerModule/isHeaderActive"] !== newState) {
          this.$store.dispatch("headerModule/toogleHeaderState", newState);
        }
      },
    },

    headerPhoto: {
      get() {
        return this.$store.getters["headerPhotoModule/isHeaderPhotoActive"];
      },
      set(newState) {
        if (
          this.$store.getters["headerPhotoModule/isHeaderPhotoActive"] !==
          newState
        ) {
          this.$store.dispatch(
            "headerPhotoModule/toogleHeaderPhotoState",
            newState
          );
        }
      },
    },

    scholars: {
      get() {
        return this.$store.getFters["scholarsModule/isScholarsActive"];
      },
      set(newState) {
        if (
          this.$store.getters["scholarsModule/isScholarsActive"] !== newState
        ) {
          this.$store.dispatch("scholarsModule/toogleScholarsState", newState);
        }
      },
    },
  },
};
</script>
