var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-app-bar',{staticStyle:{"z-index":"99"},attrs:{"app":"","color":"#000000","dark":"","id":"navContainer"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-img',{staticClass:"logoImage navLogo",staticStyle:{"cursor":"pointer"},attrs:{"src":"https://lh3.googleusercontent.com/HzodaZ9MDOP58YA3znLNUiJMVjKeVi5wu-_ZBkRZT33WMuj-iqknlmIb7DFTSKNrXp0=w2400","id":"navLogo"},on:{"load":_vm.setFooterImgWidth,"click":function($event){_vm.index
                ? _vm.goTo('#index', { duration: 300 })
                : _vm.goToRoute('/', '#index')}}}),_c('span',{staticClass:"navItems d-inline-flex d-md-none"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.open = !_vm.open}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-menu ")])],1)],1),_c('span',{staticClass:"navItems d-none d-md-inline-flex pt-2"},[_c('v-btn',{attrs:{"text":"","href":"https://plataforma.ahjende.com/","target":"_blank"}},[_vm._v(" Tecnología ")])],1),_c('span',{staticClass:"navItems d-none d-md-inline-flex pt-2"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.blog
                  ? _vm.goTo('#blog', { duration: 300 })
                  : _vm.goToRoute('/blog', '#blog')}}},[_vm._v(" Blog ")])],1),_c('span',{staticClass:"navItems d-none d-md-inline-flex pt-2"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.scholarships
                  ? _vm.goTo('#scholarships', { duration: 300 })
                  : _vm.goToRoute('/becas', '#scholarships')}}},[_vm._v(" Becas ")])],1),_c('span',{staticClass:"navItems d-none d-md-inline-flex pt-2"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.vocacional
                  ? _vm.goTo('#vocacional', { duration: 300 })
                  : _vm.goToRoute('/vocacional', '#vocacional')}}},[_vm._v(" Orientación Vocacional ")])],1),_c('span',{staticClass:"navItems d-none d-md-inline-flex pt-2"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.scholars
                  ? _vm.goTo('#scholars', { duration: 300 })
                  : _vm.goToRoute('/oferta', '#scholars')}}},[_vm._v(" Oferta ")])],1),_c('span',{staticClass:"navItems d-none d-md-inline-flex pt-2"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.cdes
                  ? _vm.goTo('#cdes', { duration: 300 })
                  : _vm.goToRoute('/cdes', '#cdes')}}},[_vm._v(" CDE's ")])],1),_c('span',{staticClass:"navItems d-none d-md-inline-flex pt-2",staticStyle:{"float":"right"}},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.weAre
                  ? _vm.goTo('#we-are', { duration: 300 })
                  : _vm.goToRoute('/somos', '#we-are')}}},[_vm._v(" Somos ")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }